//
import AthLoginService from '@/services/api/Auth/AthLogin'
import PProviderService from '@/services/api/Provider/PProvider'
import RulesService from '@/services/rules'

//
export default {
  name: 'v-view-login',
  data() {
    return {
      busy: false,
      athLogin: {},
    }
  },
  methods: {

    /**
     * 
     */
    async getProvider(pProviderId) {
      await PProviderService.getById(pProviderId).then(pProvider => {
        return this.$store.dispatch('ProviderModule/setData', {
          data: pProvider,
        })
      })
    },

    /**
     * 
     */
    handleFormReset() {
      this.athLogin = AthLoginService.toLogin()
    },

    /**
     * 
     */
    async handleFormSubmit() {
      this.busy = true
      await AthLoginService.login(this.athLogin).then(this.handleLoginSuccess).catch(this.handleLoginError)
      this.busy = false
    },

    /**
     * 
     */
    handleLoginError(payload) {
      AthLoginService.translate(payload).then(this.$toast.error)
    },

    /**
     * 
     */
    async handleLoginSuccess(payload) {

      // set auth data
      await this.$store.dispatch('AuthModule/setData', { 
        data: {
          ...payload.data,
          userAccount: undefined,
        } 
      })

      // set user data
      await this.$store.dispatch('UserModule/setData', { 
        data: payload.data.userAccount
      })

      // get provider
      if (payload.data.userAccount.profile.code === 'provider') {
        await this.getProvider(payload.data.userAccount.profile.relatedId)
      }

      //
      if (this.athLogin.remember) {
        await this.$store.dispatch('AuthModule/storeData')
        await this.$store.dispatch('ProviderModule/storeData')
        await this.$store.dispatch('UserModule/storeData')
      }

      // success ui events
      this.$toast.success('Bienvenido')
      this.$router.push('/buscar')
    },
  },
  created() {
    this.handleFormReset()
  },
  props: {

    /**
     * 
     */
    rutRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.rut,
        RulesService.max(12),
      ],
    },

    /**
     * 
     */
    passwordRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.min(6),
        RulesService.max(20),
      ],
    },
  }
}